import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { Vue as VueIntegration, CaptureConsole as CaptureConsoleIntegration, Offline as OfflineIntegration } from '@sentry/integrations'

/**
 * dsn 上sentry key 如需修改 请联系管理员
 * debug： 调试模式 默认false
 * tracesSampleRate： 采样频率 0-1 默认 1
 * denyUrls： string[] 不发送到sentry的URL
 * timeout: 超时设置 默认2000
 * release： 和build/utils/sentryUpLoadSourcesMap 中release  版本保持一致
 */
const config = {
  dsn: 'https://204d5526234d4c1f8e065df0574e8a56@o1076594.ingest.sentry.io/6080257',
  debug: false,
  sampleRate: 1.0, // 0 到 1 之间的数字，控制给定事务发送到 Sentry 的概率百分比。(0 表示 0%，1 表示 100%)同样适用于应用程序中创建的所有事务
  denyUrls: [
    /^chrome:\/\//i,
    /^facebook:\/\//i,
  ], // 不应该发送到 Sentry 的错误 URL 相匹配的字符串或正则表达式模式列表 示例：因此，如果你添加 foo.com，它也会匹配 https://bar.com/myfile/foo.com
  maxBreadcrumbs: 30, // 控制应该捕获的面包屑总数。默认值为 100
  release: `lingoace-edu-parent`,
  environment: process.env.NODE_ENV, // 环境变量
};
/**
 *
 * @param {vue} app
 * @desc 初始化sentry
 */
export const createSentry = (app) => {
  const org = location.origin
  if (org.indexOf('lingo-ace') > -1 || org.indexOf('lingoace') > -1) {
    Sentry.init({
      app,
      integrations: [
        // 程序性能自动检查
        new Integrations.BrowserTracing({
          tracingOrigins: ["localhost", "lingoace", "lingoAce", "ling-ace", /^\//],
        }),
        // vue错误
        new VueIntegration({Vue: app, attachProps: true, logErrors: true}),
        // 拦截console
        new CaptureConsoleIntegration({
          levels: ['error', 'debug']
        }),
        // 离线上报最大 数量
        new OfflineIntegration({
          maxStoredEvents: 50
        })
      ],
      ...config,
    });
    loadResourceError();
  }
};
/**
 * @name loadResourceError
 * @desc 监控页面静态资源加载报错
 */
function loadResourceError() {
  window.onerror = (msg, url, line, col, error) => {
    console.log('onerror')
    if (msg !== 'Script error.' && !url) {
      return true
    }
    setTimeout(() => {
      let data = {}
      col = col || (window.event && window.event.errorCharacter) || 0
      data.url = url
      data.line = line
      data.col = col
      data.error = error
      if (!!error && !!error.stack) {
        data.msg = error.stack.toString()
      }
      log(data)
    }, 0)
    return true
  }
  // 监听promise
  window.addEventListener('unhandledrejection', err => {
    setTimeout(() => {
      log(JSON.stringify(err))
    }, 0)
  })
}
function log(data = null, type = 'error', options = {}) {
  if (data instanceof Error) {
    Sentry.captureException(data, {
      level: type,
      logger: 'manual exception',
      tags: { options: options }
    })
  } else {
    Sentry.captureException('error', {
      level: type,
      logger: 'manual data',
      extra: {
        data: data,
        date: new Date()
      }
    })
  }
}
